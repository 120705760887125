var flktyInst;
var heroCarousel = document.querySelector('.slideshow-items');
var menuToggler = document.querySelector('.site-header .nav-toggler');

document.addEventListener('DOMContentLoaded', function () {
    if (heroCarousel) {
        flktyInst = new Flickity(heroCarousel, {
            autoPlay: 8000,
            cellAlign: 'center',
            resize: false,
            pageDots: true,
            prevNextButtons: false,
            wrapAround: true
        });
    }

    if (menuToggler) {
        menuToggler.addEventListener('click', function () {
            toggleClass(this, 'open');
            toggleElem(this.parentNode.querySelector('ul'));
        });
    }
});